import React from "react";
import {
  ClientPreferenceProps,
  CustomerDetailProps,
  CustomerProps,
  FilterProps,
  TransactionProps,
} from "../interfaces/interfaces";
import {
  CLIENT_PREFERENCES_INITIAL_STATE,
  CUSTOMER_INFO_INITIAL_STATE,
} from "../utils/data";

interface Props {
  dataLoading: boolean;
  dataSuccess: { status: boolean; message: string };
  dataError: { status: boolean; message: string };
  dataWarning: { status: boolean; message: string };
  customerList: CustomerProps[];
  factoringList: TransactionProps[];
  invoiceList: TransactionProps[];
  customerInvoicesList: TransactionProps[];
  clientPreferences: ClientPreferenceProps;
  customerInfo: CustomerDetailProps;
  searchQuery: string;
  onChangeSearchQuery: (value: string) => void;
  handleWarning: (status: boolean, message: string) => void;
  getData: ({
    endpoint,
    idClient,
    idCustomer,
    search,
    dates,
    list,
  }: {
    endpoint:
      | "in-factoring"
      | "out-factoring"
      | "customer"
      | "invoice-customer"
      | "company";
    idClient: string;
    idCustomer?: string;
    search?: string;
    dates?: [string, string] | null;
    list?: string[];
  }) => void;
  getReportData: (
    endpoint: "report-crm" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => void;
  handleCreateData: (
    endpoint: "out-factoring",
    idClient: string,
    data: any,
    next?: () => void
  ) => void;
  handleEditData: ({
    endpoint,
    idClient,
    data,
    next,
  }: {
    endpoint: "out-factoring" | "preferences" | "company";
    idClient: string;
    data: any;
    next?: () => void;
  }) => void;
  handleBulkEdit: ({
    endpoint,
    idClient,
    items,
    newValue,
    next,
  }: {
    endpoint: "invoice";
    idClient: string;
    items: string[];
    newValue?: any;
    next?: () => void;
  }) => void;
  handleDeleteData: (
    endpoint: "out-factoring",
    idClient: string,
    id: string
  ) => void;
  handleBulkDeleteData: (
    endpoint: "out-factoring",
    idClient: string,
    idList?: string[]
  ) => void;
}

const DataContext = React.createContext<Props>({
  dataLoading: false,
  dataSuccess: { status: false, message: "" },
  dataError: { status: false, message: "" },
  dataWarning: { status: false, message: "" },
  customerList: [],
  factoringList: [],
  invoiceList: [],
  clientPreferences: CLIENT_PREFERENCES_INITIAL_STATE,
  customerInvoicesList: [],
  customerInfo: CUSTOMER_INFO_INITIAL_STATE,
  searchQuery: "",
  onChangeSearchQuery: () => {},
  handleWarning: () => {},
  getData: () => {},
  getReportData: () => {},
  handleCreateData: () => {},
  handleEditData: () => {},
  handleBulkEdit: () => {},
  handleDeleteData: () => {},
  handleBulkDeleteData: () => {},
});

export default DataContext;
