import React, { useContext, useEffect, useState } from "react";
import { Button, Layout, Typography, message } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Logo, Loading } from "../../components/ui";
import { companyName } from "../../utils/company/details";
import AuthContext from "../../context/AuthContext";

const { Link } = Typography;

interface Props {
  children: JSX.Element | JSX.Element[];
}
export const PublicLayout = ({ children }: Props) => {
  const { authError, authSuccess, dataWarning, authLoading } =
    useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);
  useEffect(() => {
    if (authSuccess.status) success(authSuccess.message);
  }, [authSuccess.status]);

  useEffect(() => {
    if (authError.status) error(authError.message);
  }, [authError]);

  useEffect(() => {
    if (dataWarning.status) warning(dataWarning.message);
  }, [dataWarning]);

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };
  const warning = (message: string) => {
    messageApi.open({
      type: "warning",
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>{message}</span>
          <Button
            type="primary"
            onClick={() => messageApi.destroy()}
            style={{ width: "150px", margin: "20px" }}
          >
            I Understand
          </Button>
        </div>
      ),
      duration: 60,
    });
  };

  return (
    <Layout className="public-layout">
      {contextHolder}
      <Header className="public-layout__header">
        <div />
        <Logo mobile={false} />
      </Header>
      {isVisible || authLoading ? (
        <Content className="public-layout__content">
          <div className="public-layout__content__child">{children}</div>
        </Content>
      ) : (
        <Loading />
      )}
      <Footer className="public-layout__footer">
        ©Copyright{" "}
        <a href="https://quattroapps.com" target="_blank" rel="noreferrer">
          {companyName}
        </a>
        , {new Date().getFullYear()} - Read our{" "}
        <Link href="/terms" rel="noreferrer">
          terms
        </Link>{" "}
        and{" "}
        <Link href="/privacy" rel="noreferrer">
          Privacy Policy
        </Link>
      </Footer>
    </Layout>
  );
};
