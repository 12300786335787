import React, { useContext, useEffect, useState } from "react";
import QuickbooksContext from "./QuickbooksContext";
import { ALERT_INITIAL_STATE } from "../utils/data";
import { useRequest } from "../hooks";

import AuthContext from "./AuthContext";
interface Props {
  children: JSX.Element | JSX.Element[];
}

const QuickbooksProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const { handleLoginQuickbooks } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(ALERT_INITIAL_STATE);
  const [isSuccess, setIsSuccess] = useState(ALERT_INITIAL_STATE);

  useEffect(() => {
    setTimeout(() => {
      setIsSuccess(ALERT_INITIAL_STATE);
      setIsError(ALERT_INITIAL_STATE);
    }, 3000);
  }, [isSuccess, isError]);

  // Utils

  const handleRefreshQbData = () => {
    setIsLoading(true);
    const token = localStorage.getItem("info");
    window.location.replace(`${process.env.REACT_APP_SERVER}/?token=${token}`);
  };

  const handleGetQbData = (showSuccess: boolean, next?: () => void) => {
    const token = localStorage.getItem("info");
    if (token) {
      setIsLoading(true);
      let options: RequestInit = {
        method: "GET",
      };
      handleRequest({
        endpoint: `factoring/quickbooks/?token=${token}`,
        options,
        onSuccess: (response) => {
          if (response.success) {
            showSuccess &&
              setIsSuccess({ status: true, message: "Data Updated" });
            sessionStorage.setItem("totals", JSON.stringify(response.data));
            next && next();
          } else {
            setIsSuccess({
              status: true,
              message: "Redirecting to Quickbooks",
            });
            handleLoginQuickbooks();
          }
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          setIsSuccess({
            status: true,
            message: "Redirecting to Quickbooks",
          });
          handleLoginQuickbooks();
        },
      });
    } else {
      setIsError({ status: true, message: "An error has ocurred" });
    }
  };

  // Requests

  return (
    <QuickbooksContext.Provider
      value={{
        qbLoading: isLoading,
        qbSuccess: isSuccess,
        qbError: isError,
        handleRefreshQbData,
        handleGetQbData,
      }}
    >
      {children}
    </QuickbooksContext.Provider>
  );
};

export default QuickbooksProvider;
