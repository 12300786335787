import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Layout, message } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import Navbar from "../navigation/Navbar";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Logo, Loading } from "../../components/ui";
import QuickbooksContext from "../../context/QuickbooksContext";
import DataContext from "../../context/DataContext";
import { useMediaQuery } from "react-responsive";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    setIsVisible(false);
    isCollapted();
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, [location.pathname]);
  const { qbSuccess, qbError } = useContext(QuickbooksContext);
  const { dataError, dataSuccess, dataWarning, handleWarning } =
    useContext(DataContext);
  const [collapsed, setCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState<string | number | undefined>(
    undefined
  );
  const [siderWidthCollapsed, setSiderWidthCollapsed] = useState<
    string | number | undefined
  >(undefined);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (qbSuccess.status) success(qbSuccess.message);
  }, [qbSuccess.status]);

  useEffect(() => {
    if (qbError.status) error(qbError.message);
  }, [qbError]);

  useEffect(() => {
    if (dataError.status) error(dataError.message);
  }, [dataError]);

  useEffect(() => {
    if (dataSuccess.status) success(dataSuccess.message);
  }, [dataSuccess]);

  useEffect(() => {
    if (dataWarning.status) warning(dataWarning.message);
  }, [dataWarning]);

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };
  const isCollapted = () => {
    const currentCollapsed = collapsed;
    if (isMobile && currentCollapsed) {
      setSiderWidthCollapsed(200);
      setSiderWidth(200);
    } else if (isMobile && currentCollapsed === false) {
      setSiderWidthCollapsed(0);
      setSiderWidth(0);
    }
  };
  const warning = (message: string) => {
    messageApi.open({
      type: "warning",
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>{message}</span>
          <Button
            type="primary"
            onClick={() => {
              messageApi.destroy();
              handleWarning(false, "");
            }}
            style={{ width: "150px", margin: "20px" }}
          >
            I Understand
          </Button>
        </div>
      ),
      duration: 60,
      style: {
        marginTop: "10vh",
        marginLeft: "15vw",
      },
    });
  };

  return (
    <Layout className="protected-layout">
      {contextHolder}
      <Sider
        breakpoint="sm"
        trigger={null}
        collapsed={collapsed}
        collapsible
        collapsedWidth={siderWidthCollapsed}
        width={siderWidth}
        theme="light"
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
            setSiderWidth(0);
          }
        }}
      >
        <div className="protected-layout__logo">
          <Logo mobile={collapsed} />
        </div>
        <Navbar />
      </Sider>
      <Layout>
        <Header className="protected-layout__header">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => {
              setCollapsed(!collapsed);
              isCollapted();
            }}
          />
        </Header>
        {isVisible ? (
          <Content className="protected-layout__dashboard">
            <Content className="protected-layout__dashboard__content">
              {children}
            </Content>
          </Content>
        ) : (
          <Loading />
        )}
      </Layout>
    </Layout>
  );
};
