import React, { useState } from "react";
import { useRequest } from "../hooks";
import DataContext from "./DataContext";
import {
  ClientPreferenceProps,
  CustomerDetailProps,
  CustomerProps,
  FilterProps,
  TransactionProps,
} from "../interfaces/interfaces";
import {
  CLIENT_PREFERENCES_INITIAL_STATE,
  CUSTOMER_INFO_INITIAL_STATE,
} from "../utils/data";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const DataProvider = ({ children }: Props) => {
  const { handleRequest } = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ status: false, message: "" });
  const [isSuccess, setIsSuccess] = useState({ status: false, message: "" });
  const [customerList, setCustomerList] = useState<CustomerProps[]>([]);
  const [customerInfo, setCustomerInfo] = useState<CustomerDetailProps>(
    CUSTOMER_INFO_INITIAL_STATE
  );
  const [isWarning, setIsWarning] = useState({ status: false, message: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [invoiceList, setInvoiceList] = useState<TransactionProps[]>([]);
  const [factoringList, setFactoringList] = useState<TransactionProps[]>([]);
  const [customerInvoicesList, setCustomerInvoicesList] = useState<
    TransactionProps[]
  >([]);
  const [clientPreferences, setClientPreferences] =
    useState<ClientPreferenceProps>(CLIENT_PREFERENCES_INITIAL_STATE);

  const handleWarning = (status: boolean, message: string) => {
    setIsWarning({ status, message });
  };

  const getData = ({
    endpoint,
    idClient,
    idCustomer,
    search,
    dates,
    list,
  }: {
    endpoint:
      | "out-factoring"
      | "in-factoring"
      | "customer"
      | "invoice-customer"
      | "company";
    idClient: string;
    idCustomer?: string;
    search?: string;
    dates?: [string, string] | null;
    list?: string[];
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    let searchEncoded = search && encodeURIComponent(search);
    let endpointApi = searchEncoded
      ? `${endpoint}/${idClient}?search=${searchEncoded}`
      : idCustomer
      ? `${endpoint}/${idClient}/${idCustomer}`
      : list
      ? `${endpoint}/${idClient}/?list=${JSON.stringify({ list })}`
      : `${endpoint}/${idClient}`;
    if (dates && dates[0] && dates[1]) {
      endpointApi += `?start=${dates[0]}&end=${dates[1]}`;
    }
    handleRequest({
      endpoint: `factoring/${endpointApi}`,
      options,
      onSuccess: (response) => {
        switch (endpoint) {
          case "customer":
            setCustomerList(response.data);
            break;
          case "out-factoring":
            setCustomerInvoicesList(response.data);
            setInvoiceList(response.data);
            break;
          case "in-factoring":
            setFactoringList(response.data);
            break;
          case "invoice-customer":
            setCustomerInvoicesList(response.data);
            break;
          case "company":
            setClientPreferences(response.data);
            break;
          default:
            break;
        }
        setInvoiceList(response.data);
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        console.log(e);
      },
    });
  };
  const getReportData = (
    endpoint: "report-crm" | "logs",
    idClient: string,
    filters: FilterProps,
    next?: (response: any) => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "GET",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/?filters=${JSON.stringify(filters)}`,
      options,
      onSuccess: (response) => {
        if (response.data.length > 0) {
          setIsSuccess({ status: true, message: "Data fetched successfully" });
          next && next(response.data);
        } else {
          setIsError({ status: true, message: "No data" });
        }
        setIsLoading(false);
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        console.log(e);
      },
    });
  };

  const handleCreateData = (
    endpoint: "out-factoring",
    idClient: string,
    data: any,
    next?: () => void
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "POST",
      body: JSON.stringify(data),
    };
    handleRequest({
      endpoint: endpoint + "/" + idClient,
      options,
      onSuccess: () => {
        getData({ endpoint, idClient });
        next && next();
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
        console.log(e);
      },
    });
  };

  const handleEditData = ({
    endpoint,
    idClient,
    data,
    next,
  }: {
    endpoint: "out-factoring" | "preferences" | "company";
    idClient: string;
    data: any;
    next?: () => void;
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ updates: data }),
    };
    handleRequest({
      endpoint: `factoring/${endpoint}/${idClient}/${data._id}`,
      options,
      onSuccess: () => {
        switch (endpoint) {
          case "preferences":
            getData({ endpoint: "company", idClient });
            break;
          default:
            getData({ endpoint, idClient });
            break;
        }
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
        next && next();
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: "Please try again" });
        console.log(e);
      },
    });
  };

  const handleBulkEdit = ({
    endpoint,
    idClient,
    items,
    newValue,
    next,
  }: {
    endpoint: "invoice";
    idClient: string;
    items: string[];
    newValue?: any;
    next?: () => void;
  }) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "PUT",
      body: JSON.stringify({ idList: items, newValue }),
    };
    handleRequest({
      endpoint: `factoring/${endpoint}/bulk/${idClient}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Updated" });
        next && next();
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
      },
    });
  };

  const handleDeleteData = (
    endpoint: "out-factoring",
    idClient: string,
    id: string
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "DELETE",
    };
    handleRequest({
      endpoint: `${endpoint}/${idClient}/${id}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Item Deleted" });
        getData({ endpoint, idClient });
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({ status: true, message: e.message || "Please try again" });
      },
    });
  };

  const handleBulkDeleteData = (
    endpoint: "out-factoring",
    idClient: string,
    idList?: string[]
  ) => {
    setIsLoading(true);
    let options: RequestInit = {
      method: "DELETE",
      body: JSON.stringify({ idList }),
    };
    handleRequest({
      endpoint: `${endpoint}-bulk/${idClient}`,
      options,
      onSuccess: () => {
        setIsLoading(false);
        setIsSuccess({ status: true, message: "Items Deleted" });
        getData({ endpoint, idClient });
      },
      onError: (e) => {
        setIsLoading(false);
        setIsError({
          status: true,
          message: e.message || "Please try again",
        });
      },
    });
  };

  return (
    <DataContext.Provider
      value={{
        dataLoading: isLoading,
        dataSuccess: isSuccess,
        dataError: isError,
        dataWarning: isWarning,
        customerList,
        factoringList,
        invoiceList,
        customerInvoicesList,
        clientPreferences,
        customerInfo,
        searchQuery,
        onChangeSearchQuery: (value: string) => setSearchQuery(value),
        handleWarning,
        getData,
        getReportData,
        handleCreateData,
        handleEditData,
        handleBulkEdit,
        handleDeleteData,
        handleBulkDeleteData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
