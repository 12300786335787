import { Modal, Form, Row, Col, Select, Button, DatePicker } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../context/DataContext";
import { FilterProps } from "../../interfaces/interfaces";
import { FILTERS_INITIAL_STATUS } from "../../utils/data";
const { RangePicker } = DatePicker;

interface Props {
  open: boolean;
  onSubmit: (filters: FilterProps) => void;
  onCancel: () => void;
}

interface ListComponentProps {
  label: string;
  value: string;
}

const LIST_INITIAL_STATE = [{ label: "", value: "" }];

export const ModalFilterReport = ({ open, onSubmit, onCancel }: Props) => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<FilterProps>(FILTERS_INITIAL_STATUS);
  const { customerList } = useContext(DataContext);
  const [activityOptions, setActivityOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);
  const [customerOptions, setCustomerOptions] =
    useState<ListComponentProps[]>(LIST_INITIAL_STATE);

  useEffect(() => {
    setFilters(FILTERS_INITIAL_STATUS);
    form.setFieldsValue(FILTERS_INITIAL_STATUS);
  }, []);

  useEffect(() => {
    let optionsCustomer: ListComponentProps[] = [];
    customerList.forEach((customer) => {
      optionsCustomer.push({
        label: customer.CompanyName || customer.FullyQualifiedName,
        value: customer.Id,
      });
    });
    optionsCustomer.length > 0 && setCustomerOptions(optionsCustomer);
  }, [customerList]);

  const handleChange = (key: string, value: string[]) => {
    let query = value.length > 0 ? value : null;
    let newFilters = { ...filters, [key]: query };
    setFilters(newFilters);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());

  return (
    <Modal
      title={"Filters"}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
      centered
    >
      <Form
        layout="vertical"
        initialValues={filters}
        form={form}
        onFinish={() => {
          onSubmit(filters);
        }}
      >
        <Row justify="center">
          <Col span={20}>
            <Form.Item
              label="Activity Type"
              className="modal-filter-report__form-item"
            >
              <Select
                mode="multiple"
                allowClear
                className="modal-filter-report__block"
                options={activityOptions}
                placeholder="If none is selected, All Activity Types will be fetched"
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value) => handleChange("activity", value)}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Customer"
              className="modal-filter-report__form-item"
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="If none is selected, All Customers will be fetched"
                className="modal-filter-report__block"
                optionFilterProp="children"
                filterOption={filterOption}
                options={customerOptions}
                onChange={(value) => handleChange("customer", value)}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              label="Date Range"
              className="modal-filter-report__form-item"
            >
              <RangePicker
                className="modal-filter-report__block"
                onChange={(_, dates) => handleChange("dates", dates)}
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item className="modal-filter-report__form-item--button">
              <Button
                type="primary"
                htmlType="submit"
                className="modal-filter-report__block"
              >
                Filter
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
