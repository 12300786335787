import { Drawer, Timeline } from "antd";
import React from "react";

export const FactoringHistoryDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Drawer title="Basic Drawer" onClose={onClose} open={open}>
      <Timeline
        mode={"left"}
        items={[
          {
            label: "2015-09-01",
            children: "Create a services",
          },
          {
            label: "2015-09-01 09:12:11",
            children: "Solve initial network problems",
          },
          {
            children: "Technical testing",
          },
          {
            label: "2015-09-01 09:12:11",
            children: "Network problems being solved",
          },
        ]}
      />
    </Drawer>
  );
};
