import { Modal, Form, Row, Col, Select, DatePicker, Button, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import TextArea from "antd/es/input/TextArea";
import DataContext from "../../context/DataContext";
import QuickbooksContext from "../../context/QuickbooksContext";
import { statusOptions } from "../../utils/data";

interface ValuesProps {
  state: string;
  scheduleDate: Dayjs;
  scheduleNumber: string;
  notes: string;
}

interface Props {
  isModalOpen: boolean;
  onCancel: () => void;
  onSubmit: (values: ValuesProps) => void;
}

const INITIAL_VALUES: ValuesProps = {
  state: "",
  scheduleDate: dayjs(),
  scheduleNumber: "",
  notes: "",
};

export const ModalFactoring = ({ isModalOpen, onCancel, onSubmit }: Props) => {
  const [form] = Form.useForm();
  const { qbLoading } = useContext(QuickbooksContext);
  const { dataLoading } = useContext(DataContext);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [values, setValues] = useState(INITIAL_VALUES);
  useEffect(() => {
    form.setFieldsValue({ state: "", schedule: dayjs(), notes: "" });
    setValues(INITIAL_VALUES);
  }, [form]);

  useEffect(() => {
    values.state && setIsReadyToSubmit(true);
  }, [values]);

  return (
    <Modal
      title={"Details"}
      open={isModalOpen}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={values}
        form={form}
        onFinish={() => {
          onSubmit(values);
          //   setValues({ state: "", schedule: dayjs(), notes: "" });
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Factoring Status">
              <Select
                value={values.state}
                options={statusOptions}
                onChange={(value) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      state: value,
                    };
                  })
                }
              ></Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Schedule Date">
              <DatePicker
                name="schedule"
                format={"MM/DD/YYYY"}
                value={values.scheduleDate && dayjs(values?.scheduleDate)}
                style={{ width: "100%" }}
                allowClear={false}
                onChange={(date) => {
                  date &&
                    setValues((prev) => {
                      return {
                        ...prev,
                        schedule: date,
                      };
                    });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Schedule Number" name="scheduleNumber">
              <Input
                name="scheduleNumber"
                placeholder="Schedule Number"
                value={values.scheduleNumber}
                onChange={(event) =>
                  setValues((prev) => {
                    return { ...prev, scheduleNumber: event.target.value };
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Notes">
              <TextArea
                rows={4}
                placeholder="Notes"
                value={values.notes}
                onChange={(event) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      notes: event.target.value,
                    };
                  })
                }
              />
            </Form.Item>
          </Col>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              size="middle"
              htmlType="submit"
              disabled={!isReadyToSubmit}
              loading={dataLoading || qbLoading}
            >
              {dataLoading || qbLoading ? "Saving..." : "Save Details"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
