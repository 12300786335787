import React, { useContext } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  TeamOutlined,
  SettingOutlined,
  LogoutOutlined,
  ExportOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { onLogout, clientInfo } = useContext(AuthContext);

  const menuItems: MenuProps["items"] = [
    {
      label: "Debtors",
      key: `/customer/${clientInfo?._id.toString()}`,
      icon: <TeamOutlined className="icon" />,
    },
    {
      label: "Invoices",
      key: `/invoice/${clientInfo?._id.toString()}`,
      icon: <FileTextOutlined className="icon" />,
    },
    {
      label: "Factoring",
      key: `/factoring/${clientInfo?._id.toString()}`,
      icon: <ExportOutlined className="icon" />,
    },

    // {
    //   label: "Reports",
    //   key: `/report/${clientInfo?._id.toString()}`,
    //   icon: <FileDoneOutlined className="icon" />,
    // },
    { type: "divider" },
    {
      label: "Settings",
      key: `/settings/${clientInfo?._id.toString()}`,
      icon: <SettingOutlined className="icon" />,
      children: [
        {
          label: "Preferences",
          key: `/company/${clientInfo?._id.toString()}`,
        },
        {
          label: "Help",
          key: "/help",
        },
      ],
    },

    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      onLogout();
    } else {
      navigate(key);
    }
  };

  return (
    <>
      <Menu
        className="pt-2"
        onClick={onClick}
        defaultSelectedKeys={["1"]}
        mode="vertical"
        items={menuItems}
      />
    </>
  );
};

export default Navbar;
