import * as XLSX from "xlsx";
import {
  CustomerDetailProps,
  CustomerProps,
  TransactionProps,
  UserActionProps,
} from "../../interfaces/interfaces";
import dayjs from "dayjs";
import { USDollar } from "./currency";

interface ExportableDataProps {
  dataSheet: Object[];
  name: string;
}

export const exportLogs = (actions: UserActionProps[]) => {
  const data = actions.map((action) => {
    return {
      Date:
        action.timestamp &&
        dayjs(action.timestamp).format("MM-DD-YYYY HH:mm:ss"),
      User: action.user?.email || "",
      Action: action.action || "",
    };
  });
  handleExportExcel([{ dataSheet: data, name: "Audit Logs" }]);
};

export const exportCustomerList = (customerList: CustomerProps[]) => {
  const data = customerList.map((customer) => {
    return {
      DEBTOR: customer.FullyQualifiedName || "",
      BALANCE: customer.Balance || 0,
    };
  });
  handleExportExcel([{ dataSheet: data, name: "Customers" }], "Customer List");
};

export const exportFactoringList = (invoiceList: TransactionProps[]) => {
  const data = invoiceList.map((invoice) => {
    return {
      NUM: invoice.DocNumber || "",
      REFNO: "",
      DATE: invoice.MetaData?.CreateTime
        ? dayjs(invoice.MetaData.CreateTime).format("MM/DD/YYYY")
        : "",
      AMT: invoice.Balance || "",
      PO: "",
      DESC: "",
      DUE: invoice.DueDate ? dayjs(invoice.DueDate).format("MM/DD/YYYY") : "",
    };
  });
  handleExportExcel(
    [{ dataSheet: data, name: "FactorSoft Template" }],
    "FactorSoft Template"
  );
};

export const exportReport = (customerList: CustomerProps[]) => {
  let data: any[] = [];

  if (data.length > 0) {
    handleExportExcel(data, "Report");
  }
};

const handleExportExcel = (
  exportData: ExportableDataProps[],
  fileName?: string
) => {
  try {
    const workbook = XLSX.utils.book_new();
    exportData.forEach((data) => {
      const worksheet1 = XLSX.utils.json_to_sheet(data.dataSheet);
      XLSX.utils.book_append_sheet(workbook, worksheet1, data.name);
    });
    let name = fileName || "file";
    XLSX.writeFile(workbook, `${name}.xlsx`);
  } catch (e) {}
};
