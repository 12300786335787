import {
  ClientPreferenceProps,
  CustomerDetailProps,
  CustomerProps,
  TransactionProps,
} from "../interfaces/interfaces";

export const ALERT_INITIAL_STATE = { status: false, message: "" };

export const CUSTOMER_INITIAL_STATE: CustomerProps = {
  _id: "",
  Taxable: false,
  BillAddr: {
    Id: "",
    Line1: "",
    City: "",
    CountrySubDivisionCode: "",
    PostalCode: "",
    Lat: "",
    Long: "",
  },
  ShipAddr: {
    Id: "",
    Line1: "",
    City: "",
    CountrySubDivisionCode: "",
    PostalCode: "",
    Lat: "",
    Long: "",
  },
  Job: false,
  BillWithParent: false,
  Balance: 0,
  BalanceWithJobs: 0,
  CurrencyRef: {
    value: "",
    name: "",
  },
  PreferredDeliveryMethod: "",
  domain: "",
  sparse: false,
  Id: "",
  SyncToken: "",
  MetaData: {
    CreateTime: "",
    LastUpdatedTime: "",
  },
  GivenName: "",
  FamilyName: "",
  FullyQualifiedName: "",
  CompanyName: "",
  DisplayName: "",
  PrintOnCheckName: "",
  Active: false,
  PrimaryPhone: {
    FreeFormNumber: "",
  },
  PrimaryEmailAddr: {
    Address: "",
  },
  CollectionsProfile: {
    Preferences: "",
    AdditionalNotes: "",
  },
};

export const FILTERS_INITIAL_STATUS = {
  dates: null,
  customer: null,
  activity: null,
};

export const INVOICE_INITIAL_STATE: TransactionProps = {
  Id: "",
  SyncToken: "",
  MetaData: {
    CreateTime: new Date(),
    LastUpdatedTime: new Date(),
  },
  DocNumber: "",
  TxnDate: new Date(),
  CustomerRef: {
    value: "",
    name: "",
  },
  SalesTermRef: {
    value: "",
    name: "",
  },
  DueDate: new Date(),
  TotalAmt: 0,
  BillEmail: {
    Address: "",
  },
  Balance: 0,
  TransactionType: "Invoice",
};

export const CUSTOMER_INFO_INITIAL_STATE: CustomerDetailProps = {
  customerInfo: CUSTOMER_INITIAL_STATE,
  transactions: [INVOICE_INITIAL_STATE],
  salesEffort: {
    yearlySpending: 0,
    lastPayments: [INVOICE_INITIAL_STATE],
    costNumber: [0, 0],
  },
};

export const CLIENT_PREFERENCES_INITIAL_STATE: ClientPreferenceProps = {
  factoringCompany: {
    _id: "",
    name: "",
    phoneNumber: "",
    rate: 0,
    Client: "",
    templates: [],
  },
  preferences: {
    _id: "",
    qbCustomField: "",
    qbUpdate: false,
    qbParameter: "PrivateNote",
  },
};

export const statusOptions = [
  { label: "Not Listed", value: "Not Listed" },
  { label: "Marked to Factoring", value: "Marked to Factoring" },
  { label: "Purchased", value: "Purchased" },
  { label: "Applied Payments", value: "Applied Payments" },
  { label: "Adjustment", value: "Adjustment" },
  { label: "Reserve/Release", value: "Reserve/Release" },
];
