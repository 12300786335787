import { ColumnsType } from "antd/es/table";
import { TransactionProps } from "../../interfaces/interfaces";
import dayjs from "dayjs";
import { USDollar } from "../func/currency";
import { EditOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";

export const getColumns = ({
  actionOnEdit,
  actionOnGet,
  showFactoringOptions = false,
}: {
  actionOnEdit: (record: TransactionProps) => void;
  actionOnGet: (record: TransactionProps) => void;
  showFactoringOptions: boolean;
}) => {
  let InvoiceColumns: ColumnsType<TransactionProps> = [
    {
      title: "Num",
      dataIndex: "DocNumber",
      className: "table-row",
      sorter: {
        compare: (a, b) => a.DocNumber.localeCompare(b.DocNumber),
        multiple: 1,
      },
      fixed: "left",
    },
    {
      title: "Customer name",
      dataIndex: "CustomerRef",
      className: "table-row",
      render: (_, { CustomerRef }) => (
        <>{CustomerRef ? CustomerRef.name : ""}</>
      ),
      sorter: {
        compare: (a, b) => a.CustomerRef.name.localeCompare(b.CustomerRef.name),
        multiple: 2,
      },
      fixed: "left",
    },
    {
      title: "Created at",
      dataIndex: "MetaData",
      className: "table-row",
      render: (_, { MetaData }) => (
        <>{MetaData ? dayjs(MetaData.CreateTime).format("MM/DD/YYYY") : ""}</>
      ),
    },

    {
      title: "Due Date",
      dataIndex: "DueDate",
      className: "table-row",
      render: (params) => (
        <>{params ? dayjs(params).format("MM/DD/YYYY") : ""}</>
      ),
    },
    {
      title: "Open Balance",
      dataIndex: "Balance",
      className: "align-right table-row",
      render: (_, row) => <>{USDollar.format(row.Balance)}</>,
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 4,
      },
    },
  ];
  if (showFactoringOptions) {
    InvoiceColumns.push(
      {
        title: "Status",
        dataIndex: "currentFactoringStatus",
        className: "align-right table-row",
      },
      {
        title: "Schedule Number",
        dataIndex: "currentFactoringScheduleNumber",
        className: "align-right table-row",
      },
      {
        title: "Schedule Date",
        dataIndex: "currentFactoringScheduleDate",
        className: "align-right table-row",
        render: (_, { currentFactoringScheduleDate }) => (
          <>
            {currentFactoringScheduleDate
              ? dayjs(currentFactoringScheduleDate).format("MM/DD/YYYY")
              : ""}
          </>
        ),
      },
      {
        title: "Action",
        key: "action",
        className: "align-center table-row",
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title="Update Factoring Status" placement="top">
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => actionOnEdit(record)}
              ></Button>
            </Tooltip>
            <Tooltip title="Factoring History" placement="top">
              <Button
                type="link"
                disabled={true}
                icon={<HistoryOutlined />}
                onClick={() => actionOnGet(record)}
              ></Button>
            </Tooltip>
          </Space>
        ),
      }
    );
  } else {
    InvoiceColumns.push({
      title: "Action",
      key: "action",
      className: "align-center table-row",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Update Factoring Status" placement="top">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => actionOnEdit(record)}
            ></Button>
          </Tooltip>
          <Tooltip title="Factoring History" placement="top">
            <Button
              type="link"
              disabled={true}
              icon={<HistoryOutlined />}
              onClick={() => actionOnGet(record)}
            ></Button>
          </Tooltip>
        </Space>
      ),
    });
  }
  return InvoiceColumns;
};
