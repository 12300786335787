import {
  Button,
  Card,
  Checkbox,
  Col,
  Empty,
  Grid,
  Input,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { useParams, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  PercentageOutlined,
  EditOutlined,
} from "@ant-design/icons";
import DataContext from "../../context/DataContext";
import { Base64 } from "js-base64";

const options = Array.from(Array(100).keys()).map((_, index) => {
  return { label: index, value: index };
});

const allowQbOptions = [
  { label: "YES", value: true },
  { label: "NO", value: false },
];

const qbFieldOptions = [
  { label: "Private Note", value: "PrivateNote" },
  { label: "Custom Field", value: "CustomField" },
];

export const PreferencesClient = () => {
  const { idClient } = useParams();
  const [searchParams] = useSearchParams();
  const { onLogout } = useContext(AuthContext);
  const { getData, dataLoading, clientPreferences, handleEditData } =
    useContext(DataContext);
  const [preferenceState, setPreferenceState] = useState(
    clientPreferences.preferences
  );
  const [facCompanyState, setFacCompanyState] = useState(
    clientPreferences.factoringCompany
  );
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const [hasChangedPreference, setHasChangePreferences] = useState(false);
  const [hasChangedFacCompany, setHasChangeFacCompany] = useState(false);

  if (searchParams.get("message")) {
    const message = searchParams.get("message")?.toString();
    if (message) {
      const messageDecoded = Base64.decode(message);
      if (messageDecoded === "user must logout") {
        onLogout();
      }
    }
  }

  useEffect(() => {
    if (
      preferenceState.qbCustomField !==
        clientPreferences.preferences.qbCustomField ||
      preferenceState.qbUpdate !== clientPreferences.preferences.qbUpdate ||
      preferenceState.qbParameter !== clientPreferences.preferences.qbParameter
    )
      setHasChangePreferences(true);
  }, [preferenceState, clientPreferences]);

  useEffect(() => {
    if (
      facCompanyState.name !== clientPreferences.factoringCompany.name ||
      facCompanyState.rate !== clientPreferences.factoringCompany.rate
    )
      setHasChangeFacCompany(true);
  }, [facCompanyState, clientPreferences]);

  useEffect(() => {
    if (idClient) {
      getData({ endpoint: "company", idClient });
    }
  }, [idClient]);

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle title={"Preferences"} />
        </ColumnComponent>
        <ColumnComponent>
          <Card
            title="Quickbooks Settings"
            extra={
              <Tooltip title={"Edit"} placement="left">
                <Button
                  disabled={!hasChangedPreference}
                  type="primary"
                  size="middle"
                  onClick={() =>
                    idClient &&
                    handleEditData({
                      endpoint: "preferences",
                      idClient,
                      data: preferenceState,
                      next: () => setHasChangePreferences(false),
                    })
                  }
                  icon={!xs ? <></> : <EditOutlined />}
                >
                  {!xs ? "Edit" : ""}
                </Button>
              </Tooltip>
            }
          >
            <Row gutter={16}>
              <ColumnComponent xl={8}>
                <label>Update QB on Factoring?</label>
                <Select
                  style={{ width: "100%" }}
                  value={preferenceState.qbUpdate}
                  placeholder="Rate"
                  options={allowQbOptions}
                  onChange={(newValue) =>
                    setPreferenceState((prev) => {
                      return {
                        ...prev,
                        qbUpdate: newValue,
                      };
                    })
                  }
                />
              </ColumnComponent>
              {preferenceState.qbUpdate && (
                <>
                  <ColumnComponent xl={8}>
                    <label>Invoice Parameter</label>
                    <Select
                      style={{ width: "100%" }}
                      value={preferenceState.qbParameter}
                      placeholder="Invoice Parameter"
                      options={qbFieldOptions}
                      onChange={(newValue) =>
                        setPreferenceState((prev) => {
                          return {
                            ...prev,
                            qbParameter: newValue,
                          };
                        })
                      }
                    />
                  </ColumnComponent>
                  <ColumnComponent xl={8}>
                    <label>Custom Field Name</label>
                    <Input
                      disabled={preferenceState.qbParameter === "PrivateNote"}
                      value={preferenceState.qbCustomField}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setPreferenceState((prev) => {
                          return {
                            ...prev,
                            qbCustomField: event.target.value,
                          };
                        })
                      }
                    />
                  </ColumnComponent>
                </>
              )}
            </Row>

            {/* <Row gutter={[16, 0]}>
              <ColumnComponent xl={24}>
                <Card
                  bordered
                  hoverable
                  className="customer-client__card"
                  title="Quickbooks field name:"
                  bodyStyle={{ textAlign: "center", padding: "10px" }}
                >
                  <Input
                    value={preferenceState.qbCustomField}
                    style={{ width: "80%" }}
                    onChange={(event) =>
                      setPreferenceState((prev) => {
                        return {
                          ...prev,
                          qbCustomField: event.target.value,
                        };
                      })
                    }
                  />
                </Card>
              </ColumnComponent>
              <ColumnComponent xl={12}>
                <Card
                  bordered
                  hoverable
                  className="customer-client__card"
                  title="Quickbooks update on Factoring?"
                  bodyStyle={{ padding: "18px", textAlign: "center" }}
                >
                  <Checkbox
                    checked={preferenceState.qbUpdate}
                    onChange={(event) =>
                      setPreferenceState((prev) => {
                        return {
                          ...prev,
                          qbUpdate: event.target.checked,
                        };
                      })
                    }
                  >
                    YES/NO
                  </Checkbox>
                </Card>
              </ColumnComponent>
            </Row> */}
          </Card>
        </ColumnComponent>
        <ColumnComponent>
          <Card
            bordered
            hoverable
            title={"Factoring Company"}
            extra={
              <Tooltip title={"Edit"} placement="left">
                <Button
                  disabled={!hasChangedFacCompany}
                  type="primary"
                  size="middle"
                  onClick={() =>
                    idClient &&
                    handleEditData({
                      endpoint: "company",
                      idClient,
                      data: facCompanyState,
                      next: () => setHasChangeFacCompany(false),
                    })
                  }
                  icon={!xs ? <></> : <PlusCircleOutlined />}
                >
                  {!xs ? "Edit" : ""}
                </Button>
              </Tooltip>
            }
          >
            <ColumnComponent>
              {facCompanyState ? (
                <>
                  <ColumnComponent>
                    <Card bordered className="customer-client__card">
                      <Row
                        gutter={16}
                        style={{ padding: "10px" }}
                        justify="center"
                        align={"middle"}
                      >
                        <Col xs={20}>
                          <span>Name:</span>
                          <Input
                            value={facCompanyState.name}
                            onChange={(event) => {
                              setFacCompanyState((prev) => {
                                return {
                                  ...prev,
                                  name: event.target.value,
                                };
                              });
                            }}
                          />
                        </Col>
                        <Col xs={4}>
                          <span>Factoring Rate</span>
                          <div>
                            <Select
                              suffixIcon={<PercentageOutlined />}
                              value={facCompanyState.rate}
                              placeholder="Rate"
                              options={options}
                              onChange={(newValue) => {
                                if (newValue !== facCompanyState.rate) {
                                  setFacCompanyState((prev) => {
                                    return {
                                      ...prev,
                                      rate: newValue,
                                    };
                                  });
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </ColumnComponent>
                  {facCompanyState.templates.map((template) => (
                    <ColumnComponent>
                      <Card
                        bordered
                        className="customer-client__card"
                        title={template.name + " - Template"}
                      >
                        {template.structure.map((field, index) => (
                          <Row
                            gutter={16}
                            style={{ padding: "10px" }}
                            justify="center"
                            align={"middle"}
                          >
                            <Col xs={24} md={10}>
                              <span>Name:</span>
                              <Input
                                value={field.fieldName}
                                onChange={(event) => {
                                  setFacCompanyState((prev) => {
                                    console.log(event.target.value);
                                    return {
                                      ...prev,
                                    };
                                  });
                                }}
                              />
                            </Col>

                            <Col xs={24} md={10}>
                              <span> Source:</span>
                              <Input
                                value={field.fieldSource}
                                onChange={(event) => {
                                  setFacCompanyState((prev) => {
                                    console.log(event.target.value);
                                    return {
                                      ...prev,
                                    };
                                  });
                                }}
                              />
                            </Col>
                            <Col xs={24} md={4}>
                              <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => {}}
                              >
                                <Tooltip
                                  title="Delete Field"
                                  placement="bottom"
                                >
                                  <Button
                                    type="link"
                                    icon={<DeleteOutlined />}
                                  ></Button>
                                </Tooltip>
                              </Popconfirm>
                            </Col>
                          </Row>
                        ))}
                      </Card>
                    </ColumnComponent>
                  ))}
                </>
              ) : (
                <Empty />
              )}
            </ColumnComponent>
          </Card>
        </ColumnComponent>
      </Row>
    </>
  );
};
