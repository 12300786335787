import React from "react";

interface Props {
  qbLoading: boolean;
  qbSuccess: { status: boolean; message: string };
  qbError: { status: boolean; message: string };
  handleGetQbData: (showSuccess: boolean, next?: () => void) => void;
  handleRefreshQbData: () => void;
}

const QuickbooksContext = React.createContext<Props>({
  qbLoading: false,
  qbSuccess: { status: false, message: "" },
  qbError: { status: false, message: "" },
  handleRefreshQbData: () => {},
  handleGetQbData: () => {},
});

export default QuickbooksContext;
