import { Button, Grid, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getColumns } from "../../utils/columns/Columns";
import { TransactionProps } from "../../interfaces/interfaces";
import { exportFactoringList } from "../../utils/func/ExportFile";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { FactoringHistoryDrawer } from "../factoring";

interface Props {
  loading: boolean;
  dataSource: TransactionProps[];
  autoSelect?: boolean;
  showFactoringOptions?: boolean;
  action: (ids: string[]) => void;
  size: "small" | "middle";
  title?: string;
}

export const InvoiceListTable = ({
  loading,
  dataSource,
  showFactoringOptions,
  size,
  action,
}: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Table
        title={() => (
          <Space style={{ display: "flex", flexDirection: "row-reverse" }}>
            {showFactoringOptions && (
              <>
                <Tooltip title="Download Template">
                  <Button
                    type="primary"
                    size={size}
                    onClick={() => exportFactoringList(dataSource)}
                    icon={xs ? <DownloadOutlined /> : <></>}
                  >
                    {xs ? "" : "Download Template"}
                  </Button>
                </Tooltip>
              </>
            )}

            <Tooltip title={"Edit Selected Invoices"}>
              <Button
                type="primary"
                size={size}
                disabled={!hasSelected}
                onClick={() => action(selectedRowKeys as string[])}
                icon={xs ? <EditOutlined /> : <></>}
              >
                {xs ? "" : <>{"Edit Selected Invoices"}</>}
              </Button>
            </Tooltip>
          </Space>
        )}
        bordered
        size={size}
        loading={loading}
        columns={getColumns({
          showFactoringOptions: !!showFactoringOptions,
          actionOnEdit: (record) => {
            action([record.Id]);
          },
          actionOnGet: (record) => {
            setIsDrawerOpen(true);
          },
        })}
        dataSource={dataSource}
        rowKey={"Id"}
        rowSelection={rowSelection}
        scroll={dataSource.length > 0 ? (xs ? { x: 500 } : { x: 1000 }) : {}}
        tableLayout={xs ? "auto" : "fixed"}
      />
      <FactoringHistoryDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        // data={}
      />
    </>
  );
};
