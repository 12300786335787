import React from "react";
import {
  AdminClient,
  Login,
  Settings,
  HelpPage,
  AdminPage,
  TermsOfService,
  NotFundPage,
  PrivacyPolicy,
  CustomerPage,
  CustomerListPage,
  ReportPage,
  ReportClient,
  InvoicePage,
  InvoiceListPage,
  InvoiceDetail,
  FactoringPage,
  FactoringListPage,
  PreferencesClient,
} from "../../pages";

const routes = {
  initial: "/",
  login: "/login",
  customer: "/customer",
  customerList: "/customer/:idClient",
  factoring: "/factoring",
  factoringList: "/factoring/:idClient",
  customerDetail: "/customer/:idClient/:idCustomer",
  invoiceClient: "/invoice/:idClient",
  invoiceDetail: "/invoice/:idClient/:idInvoice",
  admin: "/admin",
  company: "/company/:idClient",
  adminClient: "/admin/:idClient",
  settings: "/settings/:idClient",
  help: "/help",
  terms: "/terms",
  privacy: "/privacy",
  report: "/report",
  reportClient: "/report/:idClient",
  notFound: "/*",
};

export const AppPages = [
  { path: routes.login, protected: false, component: <Login /> },
  { path: routes.terms, protected: false, component: <TermsOfService /> },
  { path: routes.terms, protected: true, component: <TermsOfService /> },
  { path: routes.privacy, protected: false, component: <PrivacyPolicy /> },
  { path: routes.privacy, protected: true, component: <PrivacyPolicy /> },
  { path: routes.admin, protected: true, component: <AdminPage /> },
  { path: routes.adminClient, protected: true, component: <AdminClient /> },
  { path: routes.customer, protected: true, component: <CustomerPage /> },
  {
    path: routes.customerList,
    protected: true,
    component: <CustomerListPage />,
  },
  { path: routes.factoring, protected: true, component: <FactoringPage /> },
  {
    path: routes.factoringList,
    protected: true,
    component: <FactoringListPage />,
  },

  { path: routes.customer, protected: true, component: <CustomerPage /> },
  { path: routes.initial, protected: true, component: <InvoicePage /> },
  {
    path: routes.invoiceClient,
    protected: true,
    component: <InvoiceListPage />,
  },
  {
    path: routes.invoiceDetail,
    protected: true,
    component: <InvoiceDetail />,
  },
  { path: routes.settings, protected: true, component: <Settings /> },
  { path: routes.company, protected: true, component: <PreferencesClient /> },
  { path: routes.help, protected: true, component: <HelpPage /> },
  { path: routes.report, protected: true, component: <ReportPage /> },
  { path: routes.reportClient, protected: true, component: <ReportClient /> },
  { path: routes.notFound, protected: true, component: <NotFundPage /> },
  { path: routes.notFound, protected: false, component: <NotFundPage /> },
];
