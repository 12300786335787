import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { ButtonQuickbooks } from "../quickbooks";

export const LoginForm = () => {
  const { onLogin, authLoading, handleLoginQuickbooksSSO } =
    useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const { Title } = Typography;
  const [form] = Form.useForm();
  return (
    <Row justify="center" className="login__row">
      <Col md={20} xs={24} sm={24} lg={18} xl={18} className="login__col">
        <Title level={1} className="login__title">
          Sign In
        </Title>
        <Title level={5} className="login__subtitle">
          Enter your email and password to sign in
        </Title>
        <Form
          onFinish={() => onLogin(username, pass, true)}
          layout="vertical"
          className="row-col"
          form={form}
        >
          <Form.Item
            label="User"
            name="user"
            className="login__form-item"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              placeholder="User"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            className="login__form-item"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              value={pass}
              onChange={(event) => setPass(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            className="login__form-item"
            style={{ textAlign: "center" }}
          >
            <Button
              style={{ width: "50%" }}
              type="primary"
              className="login__form-button"
              onClick={() => {
                form
                  .validateFields()
                  .then(() => onLogin(username, pass, false))
                  .catch((e) => console.log(e));
              }}
              disabled={authLoading}
            >
              Login
            </Button>
          </Form.Item>
          {/* <Form.Item className="login__form-item">
            <Button
              type="default"
              htmlType="submit"
              className="login__form-button"
              disabled={authLoading}
            >
              Quickbooks Sign In
            </Button>
          </Form.Item> */}
        </Form>
        <Divider>Or</Divider>
        <div style={{ textAlign: "center" }}>
          <ButtonQuickbooks onClick={handleLoginQuickbooksSSO} />
        </div>
      </Col>
    </Row>
  );
};
