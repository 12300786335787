import { Button, Card, Empty, Grid, Modal, Row, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { useParams, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import {
  DownloadOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { SearchInput } from "../../components/functional";
import DataContext from "../../context/DataContext";
import { USDollar } from "../../utils/func/currency";
import { CustomerProps } from "../../interfaces/interfaces";
import { Base64 } from "js-base64";
import { ColumnsType } from "antd/es/table";
import { InvoiceListTable } from "../../components/invoice";
import QuickbooksContext from "../../context/QuickbooksContext";
import { ModalFactoring } from "../../components/factoring";

export const CustomerListPage = () => {
  const { idClient } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { clientInfo, onLogout } = useContext(AuthContext);
  const { handleGetQbData } = useContext(QuickbooksContext);
  const {
    getData,
    dataLoading,
    customerList,
    customerInvoicesList,
    handleBulkEdit,
    handleWarning,
  } = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceIDs, setInvoiceIDs] = useState<string[]>([]);
  const [isModalFactoringOpen, setIsModalFactoringOpen] = useState(false);
  const [clientSelected, setClientSelected] = useState<null | string>(null);
  const [selectedCustomerList, setSelectedCustomerList] = useState<
    CustomerProps[]
  >([]);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  if (searchParams.get("message")) {
    const message = searchParams.get("message")?.toString();
    if (message) {
      const messageDecoded = Base64.decode(message);
      if (messageDecoded === "user must logout") {
        onLogout();
      } else {
        handleWarning(true, messageDecoded);
      }
    }
  }

  useEffect(() => {
    if (idClient) {
      getData({ endpoint: "customer", idClient });
    }
  }, [idClient]);

  useEffect(() => {
    if (clientSelected && idClient) {
      getData({
        endpoint: "out-factoring",
        idClient,
        list: [clientSelected],
      });
    }
  }, [idClient, clientSelected]);

  const columns: ColumnsType<CustomerProps> = [
    {
      title: "Name",
      dataIndex: "FullyQualifiedName",
      className: "table-row",
      sorter: {
        compare: (a, b) =>
          a.FullyQualifiedName.localeCompare(b.FullyQualifiedName),
        multiple: 1,
      },
      render: (_, { Id, FullyQualifiedName }) => (
        <Button
          size="small"
          type="link"
          onClick={() => {
            setSelectedRowKeys([]);
            setClientSelected(Id);
            setIsModalOpen(true);
          }}
        >
          {FullyQualifiedName}
        </Button>
      ),
    },
    {
      title: "Open Balance",
      dataIndex: "Balance",
      className: "align-right table-row",
      render: (_, row) => <>{USDollar.format(row.Balance)}</>,
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 4,
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    let filteredCustomers = customerList.filter((customer) =>
      newSelectedRowKeys.includes(customer.Id)
    );
    setSelectedCustomerList(filteredCustomers);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={clientInfo?.name + " - Debtors"}
            extra={
              <Tooltip title="Refresh Quickbooks Data">
                <Button
                  type="link"
                  icon={<ReloadOutlined className="icon--white" />}
                  size="large"
                  onClick={() => {
                    idClient &&
                      handleGetQbData(true, () =>
                        getData({ endpoint: "customer", idClient })
                      );
                  }}
                />
              </Tooltip>
            }
          />
        </ColumnComponent>
        <ColumnComponent>
          <Card
            title={
              <SearchInput
                style={{ width: xs ? "90%" : "30%" }}
                onSearch={(value: string) => {
                  if (value) {
                    idClient &&
                      getData({
                        endpoint: "customer",
                        idClient,
                        search: value,
                      });
                  } else {
                    idClient && getData({ endpoint: "customer", idClient });
                  }
                }}
              />
            }
            bordered
            className="card customer-client__card"
            extra={[
              <Tooltip title="Open Invoices related" placement="left">
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  // icon={<UnorderedListOutlined />}
                  size="middle"
                  onClick={() => {
                    if (idClient && selectedRowKeys.length > 0) {
                      getData({
                        endpoint: "out-factoring",
                        idClient,
                        list: selectedRowKeys as string[],
                      });
                      setIsModalOpen(true);
                    }
                  }}
                >
                  Open Invoices
                </Button>
              </Tooltip>,
              // <Tooltip title={"Export Debtor Data"} placement="left">
              //   <Button
              //     type="link"
              //     icon={<DownloadOutlined />}
              //     size="large"
              //     onClick={() => {
              //       selectedCustomerList.length > 0
              //         ? exportCustomerList(selectedCustomerList)
              //         : exportCustomerList(customerList);
              //     }}
              //   />
              // </Tooltip>,
            ]}
          >
            <Table
              bordered
              loading={dataLoading}
              columns={columns}
              dataSource={customerList}
              size="middle"
              rowKey={"Id"}
              rowSelection={rowSelection}
              scroll={
                customerList.length > 0 ? (xs ? { x: 0 } : { x: 1000 }) : {}
              }
              tableLayout="auto"
            />
          </Card>
        </ColumnComponent>
      </Row>
      <Modal
        width={1200}
        title={"Factoring"}
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          setClientSelected(null);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setClientSelected(null);
        }}
        footer={null}
        centered
      >
        {customerInvoicesList.length > 0 ? (
          <InvoiceListTable
            size={"small"}
            dataSource={customerInvoicesList}
            loading={dataLoading}
            autoSelect={true}
            showFactoringOptions={false}
            action={(ids) => {
              setInvoiceIDs(ids);
              setIsModalFactoringOpen(true);
            }}
          />
        ) : (
          <Empty />
        )}
      </Modal>
      <ModalFactoring
        isModalOpen={isModalFactoringOpen}
        onCancel={() => setIsModalFactoringOpen(false)}
        onSubmit={(values) => {
          if (idClient && invoiceIDs.length > 0) {
            handleGetQbData(false, () =>
              handleBulkEdit({
                endpoint: "invoice",
                idClient,
                items: invoiceIDs,
                newValue: values,
                next: () => {
                  getData({
                    endpoint: "out-factoring",
                    idClient,
                    list: clientSelected
                      ? [clientSelected]
                      : selectedCustomerList.map((customer) => customer.Id),
                  });
                  setIsModalFactoringOpen(false);
                },
              })
            );
          }
        }}
      />
    </>
  );
};
