import {
  Button,
  Card,
  DatePicker,
  Grid,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CardTitle, ColumnComponent } from "../../components/ui";
import { useParams, useSearchParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { ReloadOutlined } from "@ant-design/icons";
import { SearchInput } from "../../components/functional";
import DataContext from "../../context/DataContext";
import { Base64 } from "js-base64";
import { InvoiceListTable } from "../../components/invoice";
import QuickbooksContext from "../../context/QuickbooksContext";
import { ModalFactoring } from "../../components/factoring";
import { statusOptions } from "../../utils/data";
const { RangePicker } = DatePicker;

export const FactoringListPage = () => {
  const { idClient } = useParams();
  const [searchParams] = useSearchParams();
  const { clientInfo, onLogout } = useContext(AuthContext);
  const { getData, dataLoading, factoringList, handleBulkEdit } =
    useContext(DataContext);
  const [invoiceIDs, setInvoiceIDs] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleGetQbData } = useContext(QuickbooksContext);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    ((option && option.label) || "")
      .toLowerCase()
      .includes(input.toLowerCase());

  if (searchParams.get("message")) {
    const message = searchParams.get("message")?.toString();
    if (message) {
      const messageDecoded = Base64.decode(message);
      if (messageDecoded === "user must logout") {
        onLogout();
      }
    }
  }

  useEffect(() => {
    if (idClient) {
      getData({ endpoint: "in-factoring", idClient });
    }
  }, [idClient]);

  const handleFilterByStatus = (value: string[]) => {
    if (value.length > 0) {
      idClient && getData({ endpoint: "in-factoring", idClient, list: value });
    } else {
      idClient && getData({ endpoint: "in-factoring", idClient });
    }
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            title={clientInfo?.name + " - Factoring Invoices"}
            extra={
              <Tooltip title="Refresh Quickbooks Data">
                <Button
                  type="link"
                  icon={<ReloadOutlined className="icon--white" />}
                  size="large"
                  onClick={() => {
                    idClient &&
                      handleGetQbData(true, () =>
                        getData({ endpoint: "in-factoring", idClient })
                      );
                  }}
                />
              </Tooltip>
            }
          />
        </ColumnComponent>

        <ColumnComponent>
          <Card
            bordered
            className="card customer-client__card"
            title={
              <div className="search-input">
                <SearchInput
                  style={{ width: xs ? "100%" : "40%", padding: "5px" }}
                  onSearch={(value: string) => {
                    if (value) {
                      idClient &&
                        getData({
                          endpoint: "in-factoring",
                          idClient,
                          search: value,
                        });
                    } else {
                      idClient &&
                        getData({ endpoint: "in-factoring", idClient });
                    }
                  }}
                />
                <Select
                  mode="multiple"
                  style={{ width: xs ? "100%" : "40%", padding: "5px" }}
                  allowClear
                  size="middle"
                  options={statusOptions}
                  placeholder="Filter by Status"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  onChange={(value) => handleFilterByStatus(value)}
                ></Select>
              </div>
            }
            extra={
              !xs && (
                <RangePicker
                  size="middle"
                  placeholder={["Due Date from ", "Due Date to"]}
                  onChange={(_, dates) =>
                    idClient &&
                    getData({
                      endpoint: "out-factoring",
                      idClient,
                      dates: dates,
                    })
                  }
                />
              )
            }
          >
            {xs && (
              <div style={{ textAlign: "center" }}>
                <RangePicker
                  style={{ marginBottom: "10px", width: "90%" }}
                  size="middle"
                  placeholder={["Due Date from ", "Due Date to"]}
                  onChange={(_, dates) =>
                    idClient &&
                    getData({
                      endpoint: "out-factoring",
                      idClient,
                      dates: dates,
                    })
                  }
                />
              </div>
            )}
            <InvoiceListTable
              size="middle"
              dataSource={factoringList}
              loading={dataLoading}
              showFactoringOptions={true}
              action={(ids) => {
                setInvoiceIDs(ids);
                setIsModalOpen(true);
              }}
            />
          </Card>
        </ColumnComponent>
      </Row>
      <ModalFactoring
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={(values) => {
          if (idClient && invoiceIDs.length > 0) {
            handleGetQbData(false, () =>
              handleBulkEdit({
                endpoint: "invoice",
                idClient,
                items: invoiceIDs,
                newValue: values,
                next: () => {
                  getData({
                    endpoint: "in-factoring",
                    idClient,
                  });
                  setIsModalOpen(false);
                },
              })
            );
          }
        }}
      />
    </>
  );
};
